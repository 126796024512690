import React from "react";
import { Icon } from "@fishyvisions/fishy_kit";
import FishyThree from "../components/ThreeJS";
import AbsoluteHeading from "../components/AbsoluteHeading";
import Products from "../components/Products";
import SEO from "../components/Seo";

const Index = () => {
  return (
    <>
      <SEO />
      <div>
        <div
          className="position-absolute d-flex align-items-center"
          style={{ left: "30px", top: "30px" }}
        >
          <img
            style={{ width: "70px" }}
            src="https://fishyvisions.s3.us-east-2.amazonaws.com/logo.png"
          />
          <h1
            className="pl-4"
            style={{
              color: "black",
              fontFamily: "Avenir",
              fontWeight: "900",
              fontSize: "32px",
            }}
          >
            FISHYVISIONS
          </h1>
        </div>
        <AbsoluteHeading />
        <FishyThree />
        <Products />
      </div>
    </>
  );
};
export default Index;
