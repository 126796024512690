import React from "react";
import { Heading } from "@fishyvisions/fishy_kit";

import * as styles from "./styles.module.css";

const AbsoluteHeading = () => {
  return (
    <div
      style={{
        left: "10%",
        top: "50%",
        fontFamily: "Avenir",
        transform: "translateY(-50%)",
      }}
      className={`position-absolute ${styles.heading}`}
    >
      <h1
        style={{
          left: "10%",
          top: "20%",
          fontFamily: "Avenir",
          fontSize: "45px",
          fontWeight: "900",
          width: "470px",
        }}
      >
        Shape your world with technology that matters and makes a difference.
      </h1>
    </div>
  );
};
export default AbsoluteHeading;
