import React, { useEffect, useRef, useState, Suspense } from "react";
import { Canvas, extend } from "react-three-fiber";
import * as THREE from "three";
import "../../utils/mtl";
import "../../utils/obj";

import Object from "./Object";
import Sparks from "./Sparks";
import Effects from "./Effects";

const FishyThree = () => {
  const mouse = useRef([0, 0]);

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      <Canvas
        dpr={[1, 2]}
        camera={{ fov: 100, position: [0, 0, 10] }}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.Uncharted2ToneMapping;
        }}
      >
        <directionalLight color="white" position={[-10, 1, 1]} />
        <directionalLight color="white" position={[20, -20, 1]} />
        <directionalLight color="white" position={[50, 20, 1]} />

        <Suspense fallback={null}>
          <Object />
        </Suspense>
        <Sparks
          count={20}
          mouse={mouse}
          colors={[
            "#A2CCB6",
            "#FCEEB5",
            "#EE786E",
            "#e0feff",
            "lightpink",
            "lightblue",
          ]}
        />
        <Effects />
      </Canvas>
    </div>
  );
};

export default FishyThree;
