import React from "react";

const Products = () => {
  const products = [
    { title: "Windu", link: "https://www.windu.io/" },
    { title: "Fossy" },
    { title: "Tweakroom" },
    { title: "Remunch" },
  ];
  const listProducts = products.map((product) => {
    return (
      <div className="mb-2">
        {product?.link ? (
          <a target="_blank" href={product?.link}>
            <h1
              style={{
                color: "black",
                fontFamily: "Avenir",
                fontWeight: "900",
                textDecoration: "underline",
              }}
            >
              {product.title}
            </h1>
          </a>
        ) : (
          <h1
            style={{
              color: "black",
              fontFamily: "Avenir",
              fontWeight: "900",
              textDecoration: "underline",
            }}
          >
            {product.title}
          </h1>
        )}
      </div>
    );
  });
  return (
    <div
      className="position-absolute hidden md:block lg:block"
      style={{
        right: "30px",
        top: "50%",
        transform: " translateY(-50%)",
      }}
    >
      {listProducts}
    </div>
  );
};
export default Products;
