import React, { useRef } from "react";
import objUrl from "./Windu.obj";
import { useLoader, useFrame } from "react-three-fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useMediaQuery } from "react-responsive";

const Object = () => {
  const ref = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const object = useLoader(OBJLoader, objUrl);

  useFrame(() => {
    object.rotation.y += 0.01;
    object.rotation.x += 0.01;
  });
  const position = isMobile ? [0, -7, 0] : [10, 0, 0];

  return (
    <group ref={ref}>
      <primitive
        position={position}
        rotation={[0, 0, 0]}
        scale={[0.02, 0.02, 0.02]}
        object={object}
      />
    </group>
  );
};

export default Object;
